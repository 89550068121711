@charset 'UTF-8';
@import "/Users/VuongHuy/Website/vhost/suimin/src/scss/utility/_burger.scss";
@import "/Users/VuongHuy/Website/vhost/suimin/src/scss/utility/_flex.scss";
@import "/Users/VuongHuy/Website/vhost/suimin/src/scss/utility/_media-queries.scss";
@import "/Users/VuongHuy/Website/vhost/suimin/src/scss/utility/_mixin.scss";

#header {
  opacity: 0;
  @include transition_c(opacity 1s);
  &.on {
    opacity: 1;
  }
}
.js-rellax {
  @include transition_c(transform .3s);
}
.texture-icon {
  overflow: hidden;
  position: absolute;
  &.ti1 {
    width: 284px;
    height: 274px;
    background: url($url_img_wpcontent+'common/texture1.png') 0 0/100% auto no-repeat;
  }
  &.ti2 {
    width: 276px;
    height: 236px;
    background: url($url_img_wpcontent+'common/texture2.png') 0 0/100% auto no-repeat;
  }
  &.ti3 {
    width: 266px;
    height: 192px;
    background: url($url_img_wpcontent+'common/texture3.png') 0 0/100% auto no-repeat;
  }
}
.top-mainvisual {
  position: relative;
  height: 100vh;
  padding-left: 60px;
  z-index: 1;
  opacity: 0;
  @include transition_c(opacity 1s);
  &.on {
    opacity: 1;
  }
  @include SP {
    padding-left: 0;
    .ios.iphoneX &,
    .ios.ios12 &,
    .ios.ios13 &,
    .ios.ios14 & {
      height: calc(100vh - 112px);
    }
    .ios.iphone678 &,
    .ios.ios11.iphone678 &
    .ios.ios12.iphone678 &,
    .ios.ios13.iphone678 &,
    .ios.ios14.iphone678 & {
      height: calc(100vh - 75px);
    }
  }
  &:after {
    content: '';
    width: calc(100% - 83px);
    height: 100%;
    background: url($url_img_wpcontent+'common/f_bg.jpg') 0 0 repeat;
    position: absolute;
    top: 90px;
    left: 0;
    z-index: -1;
    @include SP {
      width: calc(100% - 50px);
      top: inherit;
      bottom: -136px;
    }
  }
  .mv-title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    @include translate(0,-50%);
    @include ffYM;
    @include SP {
      @include translate(0,130px);
      top: 0;
    }
    .sub-ttl {
      color: #fff;
      @include font(20,34,200,600);
      @include SP {
        @include font(16,20,200);
        margin-bottom: 20px;
      }
    }
    .ttl {
      @include font(46,78,200,500);
      color: #fff;
      @include SP {
        @include font(36,68,200);
      }
    }
    .sub-ttl,
    .ttl {
      span {
        display: inline-block;
        opacity: 0;
        @include translate(60px,0);
        transition-duration: 2.2s;
        transition-property: opacity,transform;
        transition-timing-function: cubic-bezier(.165,.84,.44,1);
      }
      &.is-animated {
        span {
          opacity: 1;
          @include translate(0,0);
        }
      }
    }
  }
  .mv-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    margin-left: 60px;
    @include SP {
      margin-left: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .mv-label {
    position: absolute;
    bottom: 38px;
    right: 76px;
    z-index: 1;
    @include flexbox();
    opacity: 0;
    @include transition_c(opacity 1s);
    &.on {
      opacity: 1;
    }
    @include SP {
      right: 10px;
      bottom: 30px;
    }
    .label-item {
      margin: 0 7px;
      @include SP {
        margin: 0 5px;
      }
      img {
        @include SP {
          max-width: 110px;
          width: 100%;
        }
      }
    }
  }
  @include keyframes(scrolldown) {
    0% {
      visibility: visible;
      @include translate(-90%,0);
    }
    100% {
      visibility: visible;
      @include translate(150%,0);
    }
  }
  .mv-scroll {
    height: 60px;
    width: 130px;
    position: absolute;
    left: 5px;
    bottom: 30px;
    @include transform(rotate(90deg) scaleX(-1))
    transform-origin: left bottom;
    @include flexbox();
    @include align-items(center);
    @include transition_c(opacity 1s);
    opacity: 0;
    &.on {
      opacity: 1;
    }
    @include SP {
      display: none !important;
    }
    .scroll-box {
      @include transform(scaleX(-1));
      @include flexbox();
      @include align-items(center);
    }
    .txt {
      @include flex(0 0 auto);
      @include ffYM;
      @include font(10,10,200,600);
      color: #fff;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      top: 3px;
    }
    .scrolline {
      display: inline-block;
      overflow: hidden;
      img {
        @include add_prefix(animation,scrolldown 3s infinite linear);
      }
    }
  }
}
.top-sleep {
  position: relative;
  z-index: 2;
  margin-top: 50px;
  @include SP {
    margin-top: 22px;
  }
  .outer1366 {
    padding: 184px 0 210px;
    position: relative;
    overflow: hidden;
    @include SP {
      padding: 0 0 37px;
    }
  }
  .sleep-img-right {
    position: absolute;
    right: 0;
    width: 37.4%;
    top: 0;
    text-align: right;
    @include SP {
      position: relative;
      width: 100%;
      padding-left: 40px;
    }
    .i-img1 {
      position: absolute;
      bottom: -34%;
      left: 0;
      max-width: 27.45%;
      z-index: 1;
      @include SP {
        bottom: -60px;
        max-width: 24%;
        left: 40px;
      }
    }
    .i-img2 {
      max-width: 81.17%;
      margin-left: auto;
      @include SP {
        max-width: 80.6%;
      }
    }
  }
  .sleep-img-left {
    width: 16.83%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: 130px;
    @include SP {
      display: none !important;
    }
  }
  .sleep-boxes {
    @include SP {
      margin-top: 78px;
    }
  }
  .texture-icon {
    &.ti1.lt {
      left: 22px;
      top: -30px;
      z-index: -1;
      @include SP {
        left: inherit;
        right: -26px;
        top: -60px;
      }
    }
    &.ti2.rb {
      right: 16px;
      bottom: -50px;
      @include SP {
        display: none;
      }
    }
  }
  .sleep-box-title {
    .vertical-title {
      position: relative;
      @include font(32,80,350,500);
      @include ffYM;
      @include writing-mode(v,rl);
      @include SP {
        @include font(32,68,350,500);
        margin: 0 auto;
      }
      .r2 {
        padding-top: 90px;
        @include SP {
          padding-top: 47px;
        }
      }
    }
  }
  .sleep-box-content {
    width: 75%;
    margin-left: auto;
    margin-top: -24px;
    @include SP {
      margin: 0;
      width: 100%;
      padding-top: 48px;
    }
    .sbc-ttl {
      @include ffYM;
      @include font(20,24,200,600);
      margin-bottom: 48px;
      @include SP {
        margin-bottom: 37px;
      }
    }
    .sbc-text {
      @include SP {
        position: relative;
        height: 190px;
        overflow: hidden;
        &:after {
          content: '';
          background: rgb(255,255,255);
          background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 140px;
          @include transition_c(opacity .3s);
        }
        &.is-open {
          height: 100%;
          &:after {
            opacity: 0;
          }
        }
      }
      p {
        @include font(14,34,80);
        @include SP {
          @include font(14,28);
        }
        &:not(:last-child) {
          margin-bottom: 34px;
          @include SP {
            margin-bottom: 28px;
          }
        }
      }
    }
    .btn-readmore {
      text-align: center;
      max-width: 135px;
      width: 100%;
      display: block;
      margin: 6px auto 0; 
      border: 1px solid $color_black;
      @include font(12,12,200,500);
      @include ffYM;
      padding: 8px;
      @include min-screen(768px) {
        display: none !important;
      }
      &.is-active {
        margin-top: 30px;
      }
    }
  }
}

.top-parallax {
  height: 420px;
  overflow: hidden;
  position: relative;
  @include SP {
    height: 150px;
  }
  .img-parallax {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    @include translate(-50%,-54%);
    z-index: -1;
  }
  .img-parallax-inner {
    @include transition_c(transform .3s);
  }
  img {
    width: 100%;
  }
}

.top-about {
  margin-top: 146px;
  position: relative;
  overflow: hidden;
  @include SP {
    margin-top: 0;
    padding-top: 40px;
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #F7F7F5;
    position: absolute;
    top: 140px;
    left: 0;
    z-index: -1;
  }
  .about-box-main {
    @include flexbox();
    @include flex-wrap(wrap);
    position: relative;
    z-index: 2;
    .texture-icon {
      &.ti3.lt {
        top: -42px;
        right: 144px;
        z-index: -1;
        @include SP {
          top: -20px;
          right: inherit;
          left: -47px;
        }
      }
    }
    .about-box-title {
      width: 33%;
      @include flexbox();
      @include justify-content(flex-end);
      margin-top: 78px;
      padding-right: 13.17%;
      position: relative;
      z-index: 1;
      @include min-screen(992px) {
        padding-right: 180px;
      }
      @include SP {
        @include justify-content(flex-start);
        margin-top: 0;
        width: 100%;
        padding-right: 0;
        padding-left: 20px;
      }
    }
    .vertical-title {
      @include writing-mode(v,rl);
      @include ffYM;
      @include font(30,40,390,500);
      text-align: end;
      @include SP {
        @include font(26,40,300)
      }
      .row-ttl {
        display: inline-block;
        margin-bottom: 56px;
        @include SP {
          margin-bottom: 0;
        }
      }
      .r1 {
        @include SP {
          padding-bottom: 28px;
        }
      }
      .row-ttlen {
        @include ffBenne;
        color: $color_dark_blue;
        @include font(14,20,80);
        padding-top: 64px;
        margin-right: 30px;
        position: relative;
        display: inline-block;
        @include SP {
          margin-bottom: 7px;
          margin-right: 34px;
        }
        &:before {
          content: '';
          width: 1px;
          height: 53px;
          background: $color_dark_blue;
          position: absolute;
          top: 0;
          left: 50%;
        }
      }
    }
    .about-box-article {
      width: 67%;
      margin-left: auto;
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      position: relative;
      z-index: 1;
      @include SP {
        width: 100%;
        display: block;
        padding-left: 21px;
        padding-bottom: 28px;
        margin-top: 23px;
        .slick-track {
          margin: 0;
          @include flexbox();
        }
        .slick-list {
          padding-right: 32px;
        }
        .slick-slide {
          margin-right: 16px;
          float: none;
          >div {
            height: 100%;
            line-height: 0;
          }
        }
      }
      .aba-item {
        width: calc(50% - 10px);
        padding: 10px 0;
        @include SP {
          width: 100%;
          padding: 0;
          height: 100%;
        }
      }
      .aba-item-wrapper {
        @include flexbox();
        @include flex-direction(column);
        height: 100%;
      }
      .aba-item-img {
        @include aspect-ratio(447,213);
        @include flex(0 0 auto);
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .aba-item-inf {
        background: #fff;
        padding: 20px 30px;
        min-height: 235px;
        height: 100%;
        @include flexbox();
        @include flex-direction(column);
        @include SP {
          padding: 20px 20px 15px;
        }
        .aba-i-ttl {
          @include font(18,34,80,600);
          @include ffYM;
          margin-bottom: 12px;
          @include flexbox();
          @include align-items(center);
          @include SP {
            @include font(16,28);
            margin-bottom: 10px;
            min-height: 56px;
          }
        }
        .aba-i-txt {
          @include font(14,28,80);
          margin-bottom: 20px;
        }
        .cm-btn01 {
          margin-top: auto;
        }
      }
      .slick-dots {
        bottom: 0;
        width: 100%;
        top: inherit;
        right: 0
      }
    }
  }
  .about-box-shopmap {
    .outer1366 {
      position: relative;
      padding-top: 155px;
      padding-bottom: 322px;
      @include SP {
        padding: 0 0 172px;
        margin-top: 20px;
      }
    }
    .map {
      position: absolute;
      right: -33%;
      top: -13%;
      pointer-events: none;
      width: 84.91%;
      z-index: 1;
      @include min-screen(768px) {
        width: 100%;
      }
      @include min-screen(992px) {
        width: 84.91%;
      }
      @include SP {
        top: 0;
        position: relative;
        right: inherit;
        left: -36px;
        >img {
          max-width: 644px;
        }
      }
      .map-link-i {
        pointer-events: auto;
        display: inline-block;
        position: absolute;
        @include font(14,22,80,600);
        @include ffYM;
        color: $color_dark_blue;
        z-index: 2;
        @include SP {
          @include font(12,18);
        }
        .icon {
          width: 23px;
          display: inline-block;
          float: left;
          overflow: hidden;
          margin-right: 12px;
          margin-top: 5px;
          @include SP {
            margin-right: 8px;
          }
        }
        &.col {
          .icon {
            display: block;
            margin: 0 auto;
            float: none;
            margin: 0 auto 15px;
            text-align: center;
            @include SP {
              margin: 0 0 10px 10px;
            }
          }
        }
        &.location1 {
          top: 22.6%;
          left: 18.2%;
          @include SP {
            top: 19.6%;
            left: 30.2%;
          }
        }
        &.location2 {
          top: 38.5%;
          left: 27.3%;
          @include SP {
            top: 35.5%;
            left: 43.3%;
          }
        }
        &.location3 {
          top: 54.6%;
          left: 32.4%;
          @include SP {
            top: 49.6%;
            left: 60.4%;
          }
        }
      }
    }
    .shop-box-list{
      @include SP {
        position: relative;
        z-index: 1;
        &:after {
          content: '';
          background: rgb(247,247,245);
          background: linear-gradient(180deg, rgba(247,247,245,0) 0%, rgba(247,247,245,1) 100%);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 208px;
          z-index: -1;
        }
      }
    }
    .shop-list {
      max-width: 480px;
      width: 48%;
      @include SP {
        width: 100%;
        max-width: 100%;
        padding-top: 80px;
        margin-top: -195px;
      }
    }
    .shop-list-item {
      margin-bottom: 30px;
      @include SP {
        margin-bottom: 20px;
      }
      .shop-list-wrapper {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
      }
      .slw-img {
        width: 53%;
        @include aspect-ratio(254,170);
        position: relative;
        overflow: hidden;
        @include SP {
          width: 39.5%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .slw-ctn {
        width: 47%;
        padding-left: 30px;
        @include SP {
          width: 60.5%;
          padding-left: 13px;
        }
        .slw-ttl {
          @include font(18,30,80,600);
          @include ffYM;
          margin-bottom: 14px;
          @include min-screen(768px) {
            @include font(14,26);
          }
          @include min-screen(992px) {
            @include font(16,26);
          }
          @include min-screen(1200px) {
            @include font(18,30);
          }
          @include SP {
            @include font(16,26);
            margin-bottom: 5px;
          }
        }
      }
    }
    .cm-btn02 {
      margin-left: 60px;
      margin-top: 90px;
      @include SP {
        margin: 54px auto 0;
      }
    }
  }
}

.top-store {
  margin-top: -190px;
  position: relative;
  @include SP {
    margin-top: -92px;
  }
  .store-list {
    @include flexbox();
    @include flex-wrap(wrap);
    padding: 0 18px;
    @include SP {
      padding: 0 0 26px 21px;
      .slick-list {
        padding-right: 42px;
      }
      .slick-slide {
        margin-right: 23px;
        >div {
          line-height: 0;

        }
      }
    }
  }
  .store-list-item {
    padding: 20px 10px;
    @include SP {
      padding: 0;
    }
    &.col3 {
      width: 33.33%;
      @include SP {
        width: 100%;
      }
    }
    &.col4 {
      width: 25%;
      @include min-screen(768px) {
        width: 33.33%;
      }
      @include min-screen(992px) {
        width: 25%;
      }
      @include SP {
        width: 100%;
      }
      .store-item-wrapper {
        padding-top: 25px;
        @include SP {
          padding-top: 22px;
        }
        &:after {
          height: calc(100% - 40px);
          @include SP {
            height: calc(100% - 34px);
          }
        }
      }
    }
  }
  .store-item-wrapper {
    @include flexbox();
    position: relative;
    z-index: 1;
    padding-top: 33px;
    @include SP {
      padding-top: 22px;
    }
    &:after {
      content: '';
      background: url($url_img_wpcontent+'common/texture4.jpg') 0 0/cover no-repeat;
      width: calc(100% - 35px);
      height: calc(100% - 50px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      @include SP {
        width: calc(100% - 24px);
        height: calc(100% - 34px);
      }
    }
  }
  .sli-title {
    width: 75px;
    @include flex(0 0 auto);
    @include flexbox();
    @include justify-content(center);
    @include min-screen(768px) {
      width: 50px;
    }
    @include min-screen(1200px) {
      width: 75px;
    }
    @include SP {
      width: 50px;
    }
    .ttl {
      @include writing-mode(v,rl);
      @include ffYM;
      @include font(18,20,230,600);
      @include min-screen(768px) {
        @include font(13,20)
      }
      @include min-screen(992px) {
        @include font(14,20);
      }
      @include min-screen(1200px) {
        @include font(18,20)
      }
      @include SP {
        @include font(16,20);
      }
    }
  }
  .sli-info {
    width: 100%;
  }
  .sli-btn {
    text-align: right;
    padding-right: 15px;
    margin-top: 2px;
    margin-bottom: 12px;
    @include SP {
      margin: 8px 0;
      padding-right: 10px;
    }
    .cm-btn01 {
      @include SP {
        @include font(12)
      }
    }
  }
  .sli-img {
    @include aspect-ratio(347,230);
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
  }
  .cm-btn02 {
    margin-top: 60px;
    @include SP {
      margin-top: 43px;
    }
  }
  .slick-dots {
    @include SP {
      width: 100%;
      right: inherit;
      left: 0;
      bottom: 0;
      top: inherit;
    }
  }
}

.top-blog {
  margin-top: 118px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include SP {
    margin-top: 0;
    padding-top: 67px;
    padding-bottom: 80px;
  }
  &:after {
    content: '';
    background: #F7F7F5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 25%;
    left: 0;
    z-index: -1;
  }
  .outer1366 {
    position: relative;
  }
  .texture-icon.ti1.rt {
    right: 38px;
    top: -12px;
    pointer-events: none;
    @include SP {
      right: -98px;
      top: -120px;
    }
  }
  .cm-ttl01 {
    span {
      @include SP {
        padding: 0 44px;
      }
    }
  }
  .blog-list {
    @include flexbox();
    @include flex-wrap(wrap);
    margin: 0 -8px;
    overflow: hidden;
    padding-top: 70px;
    @include SP {
      padding-top: 12px;
    }
    .blog-list-item {
      width: 25%;
      padding: 12px 8px;
      @include min-screen(768px) {
        width: 50%;
      }
      @include min-screen(992px) {
        width: 25%;
      }
      @include SP {
        width: 100%;
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          display: none !important;
        }
      }
      &.feature-article {
        width: 50%;
        @include min-screen(768px) {
          width: 100%;
        }
        @include min-screen(992px) {
          width: 50%;
        }
        @include SP {
          width: 100%;
        }
        .biw-info {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          background: transparent;
          height: auto;
          z-index: 1;
          padding: 35px 0 0;
          width: 100%;
          &:after {
            content: '';
            background: rgb(255,255,255);
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: .5;
          }
        }
        .biw-img {
          @include SP {
            width: 100%;
            @include aspect-ratio(334,220);
          }
        }
        .biw-ttl {
          color: #fff;
          padding: 0 30px;
          @include SP {
            padding: 0 25px;
            margin-bottom: 12px;
          }
        }
        .biw-meta {
          line-height: 0;
          .biw-cate {
            position: static;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
          }
          .biw-date {
            color: #fff;
            display: inline-block;
            vertical-align: middle;
            margin-top: 0;
          }
        }
      }
    }
    .blog-item-wrapper {
      position: relative;
      height: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include SP {
        height: auto;
        display: block;
      }
    }
    .label-new {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      @include SP {
        width: 43px;
      }
    }
    .biw-img {
      @include aspect-ratio(238,160);
      overflow: hidden;
      position: relative;
      @include flex(0 0 auto);
      @include SP {
        width: 42.5%;
        height: 100%;
        @include aspect-ratio(142,95);
      }
      >img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
      }
    }
    .biw-box {
      position: relative;
      @include SP {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
        width: 100%;
      }
    }
    .biw-b-meta {
      @include SP {
        padding-left: 16px;
        width: 57.5%;
      }
    }
    .biw-cate {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin-bottom: -5px;
      line-height: 0;
      font-size: 0;
      @include SP {
        position: static;
      }
      span {
        color: #fff;
        background: $color_dark_blue;
        text-align: center;
        display: inline-block;
        @include font(12,12,80);
        min-width: 137px;
        padding: 4px 5px;
        margin: 0 5px 5px 0;
      }
    }
    .biw-info {
      background: #fff;
      height: 100%;
      padding: 20px;
      @include flexbox();
      @include flex-direction(column);
      @include SP {
        padding: 0;
        background: transparent;
        height: auto;
        width: 100%;
      }
    }
    .biw-ttl {
      @include font(16,24,40);
      margin-bottom: 12px;
      @include SP {
        margin-bottom: 0;
        margin-top: 16px;
      }
    }
    .biw-date {
      @include ffBenne;
      @include font(14,14,80);
      margin-top: auto;
      @include SP {
        margin-top: 8px;
      }
    }
  }
  .cm-btn02 {
    margin-top: 44px;
    @include SP {
      margin-top: 30px;
    }
  }
}

.top-blog-child {
  margin-top: 100px;
  @include SP {
    margin-top: 70px;
  }
  .blog-child-wrapper {
    @include flexbox();
    @include flex-wrap(wrap);
  }
  .blog-child-title {
    width: 20%;
    padding-top: 30px;
    @include min-screen(768px) {
      width: 30%;
    }
    @include min-screen(992px) {
      width: 20%;
    }
    @include SP {
      width: 100%;
      padding-top: 0;
      @include flexbox();
      @include justify-content(space-between);
    }
    .ttl {
      @include ffYM;
      @include font(30,30,200,500);
      margin-bottom: 27px;
      @include SP {
        @include font(26,26)
      }
    }
    .btn {
      text-align: right;
      margin-right: 13px;
      @include SP {
        margin: 5px 0 0;
        @include flex(0 0 auto);
      }
    }
  }
  .blog-child-content {
    width: 80%;
    @include min-screen(768px) {
      width: 70%;
    }
    @include min-screen(992px) {
      width: 80%;
    }
    @include SP {
      width: 100%;
      margin-top: 5px;
    }
    .blog-child-list {
      .bcl-item {
        @include flexbox();
        border-bottom: 1px solid #E3E3E3;
        padding: 28px 0;
        @include SP {
          padding: 17px 0 11px;
          @include flex-direction(column);
        }
        &:first-child {
          border-top: 1px solid #E3E3E3;
        }
      }
      .bcl-item-date {
        width: 120px;
        @include flex(0 0 auto);
        padding: 0 10px;
        @include font(14,14,80);
        @include ffBenne;
        text-align: center;
        margin-top: 8px;
        @include SP {
          width: 100%;
          text-align: left;
          padding: 0;
          margin: 0 0 5px;
        }
      }
      .bcl-item-ttl {
        width: 100%;
        span {
          display: inline-block;
          text-decoration: underline;
          @include font(16,24,80);
        }
      }
    }
  }
}

.top-voice {
  margin-top: 136px;
  position: relative;
  @include SP {
    margin-top: 68px;
  }
  &:after {
    content: '';
    width: calc((100% - (100% - 1366px) / 2) - 82px);
    height: 70%;
    background: #F7F7F5;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .outer1366 {
    position: relative;
    padding-bottom: 112px;
    overflow: hidden;
    @include SP {
      padding-bottom: 82px;
    }
  }
  .texture-icon.ti2.lt {
    top: -40px;
    left: 0;
    pointer-events: none;
    @include SP {
      display: none;
    }
  }
  .voice-list {
    padding-left: 13.4%;
    padding-top: 30px;
    margin-top: 48px;
    @include SP {
      padding-left: 21px;
      margin-top: 34px;
      padding-top: 0;
      padding-bottom: 35px;
    }
    .slick-list {
      padding-right: 64px;
      @include SP {
        padding-right: 32px;
      }
    }
    .slick-track {
      @include flexbox();
      margin: 0;
    }
    .slick-slide {
      margin-right: 23px;
      float: none;
      @include SP {
        margin-right: 16px;
      }
      >div {
        height: 100%;
        line-height: 0;
      }
    }
    .voice-list-item {
      height: 100%;
      .voice-item-wrapper {
        background: #fff;
        height: 100%;
        @include flexbox();
        @include flex-direction(column);
      }
      .label-new {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        @include SP {
          width: 43px;
        }
      }
      .viw-img {
        overflow: hidden;
        @include aspect-ratio(350,234);
        @include flex(0 0 auto);
        @include SP {
          @include aspect-ratio(306,205);
        }
        >img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .viw-info {
        padding: 25px 30px;
        @include flexbox();
        @include flex-direction(column);
        height: 100%;
        @include SP {
          padding: 20px 25px 30px;
        }
        .viw-ttl {
          @include font(16,24,40);
          padding-bottom: 20px;
          @include SP {
            padding-bottom: 17px;
          }
        }
        .viw-stt {
          border-top: 1px solid #E3E3E3;
          @include font(12,16,80);
          margin-top: auto;
          padding-top: 20px;
          @include SP {
            padding-top: 14px;
          }
        }
      }
    }
    .slick-arrow {
      position: absolute;
      width: 35px;
      height: 5px;
      right: 0;
      top: 0;
      z-index: 2;
      padding: 0;
      @include transition_c(opacity .3s);
      @include SP {
        top: inherit;
        bottom: 2px;
      }
      @include HOVER {
        &:hover {
          opacity: .7;
        }
      }
      &.slick-next {
        right: 87px;
        background: url($url_img_wpcontent+'common/icon/nav_next.svg') 0 0/100% auto no-repeat;
        @include SP {
          right: inherit;
          left: 50%;
          @include translate(-50%,0);
        }
      }
      &.slick-prev {
        right: 235px;
        left: inherit;
        background: url($url_img_wpcontent+'common/icon/nav_prev.svg') 0 0/100% auto no-repeat;
        @include SP {
          right: inherit;
          left: 50%;
          @include translate(-50%,0);
        }
      }
    }
  }
  .slick-dots {
    @include SP {
      bottom: 0;
      top: inherit;
      right: inherit;
      left: 50%;
      width: auto;
      @include translate(-50%,0);
    }
  }
  .cm-btn02 {
    margin-top: 50px;
  }
}

.top-intro {
  background: url($url_img_wpcontent+'top/b_img.jpg') center top/cover no-repeat;
  padding: 95px 0;
  margin-top: 160px;
  @include SP {
    padding: 44px 0 52px;
    margin-top: 0;
    background-image: url($url_img_wpcontent+'top/b_img_sp.jpg');
  }
  .top-intro-box {
    max-width: 800px;
    margin: 0 auto;
  }
  .intro-ttl {
    color: #fff;
    text-align: center;
    @include font(24,44,200,500);
    @include ffYM;
    margin-bottom: 50px;
    @include SP {
      @include font(20,40);
      margin-bottom: 27px;
    }
  }
  .intro-text {
    p {
      color: #fff;
      @include font(14,28,80);
      @include SP {
        @include font(14,24)
      }
      &:not(:last-child) {
        margin-bottom: 28px;
        @include SP {
          margin-bottom: 24px;
        }
      }
    }
  }
}



